import { User } from '../models/user.model';

export class FetchUser {
    static readonly type = '[User] Fetch User';
    constructor(public payload: string) { }
}

export class FetchUserList {
    static readonly type = '[User] Fetch List';
    constructor(public limit: number, public skip: number) { }
}

export class FetchUserByName {
    static readonly type = '[User] Fetch List By Name';
    constructor(public name: string) { }
}

export class FetchTotalUsers {
    static readonly type = '[User] Fetch List Total';
}

export class GetLoggedUser {
    static readonly type = '[User Logged] Fetch Logged User';
}

export class CreateUser {
    static readonly type = '[User] Create';
    constructor(public payload: User) { }
}

export class UpdateUser {
    static readonly type = '[User] Update';
    constructor(public payload: User) { }
}

export class DeleteUser {
    static readonly type = '[User] Delete';
    constructor(public payload: string) { }
}

export class ChangePassword {
    static readonly type = '[User] Change Password';
    constructor(public username: string, public oldPassword: string, public newPassword: string) { }
}

export class requestRecovery {
    static readonly type = '[User] Resquest Recovery';
    constructor(public email: string) { }
}

export class RecoveryPassword {
    static readonly type = '[User] Recovery Password';
    constructor(public id: string, public newPassword, public confirmNewPassword: string) { }
}