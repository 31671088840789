import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { JwtModule } from '@auth0/angular-jwt';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSoapModule } from 'ngx-soap';
import { FieldState } from './states/field.state';
import { AnalystState } from './states/analyst.state';
import { SummaryState } from './states/summary.state';
import { ContractState } from './states/contract.state';
import { LoadingState } from './states/loading.state';
import { LoadingComponent } from './components/_ui/loading/loading.component';
import { AuthService } from './services/auth.service';
import { ContextService } from './services/context.service';
import { EmailState } from './states/email.state';
import { UserState } from './states/user.state';

import { environment } from '../environments/environment';
import { AlertState } from './states/alert.state';
import { AlertComponent } from './components/_ui/alert/alert.component';
import { BenefitState } from './states/benefit.state';
import { ProcessState } from './states/process.state';
import { PipesModule } from './pipes/pipes.module';
import { PhoneState } from './states/phone.state';
import { QueryState } from './states/query.state';
import { EvaluationState } from './states/evaluation.state';


export function jwtTokenGetter() {
  return localStorage.getItem('id_token');
}

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    AlertComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    PipesModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: ['localhost:8080', 'my.lfs-inc.com'],
        blacklistedRoutes: []
      }
    }),
    NgxsModule.forRoot([
      FieldState,
      AnalystState,
      SummaryState,
      ContractState,
      EmailState,
      LoadingState,
      UserState,
      BenefitState,
      AlertState,
      ProcessState,
      PhoneState,
      QueryState,
      EvaluationState
    ]),
    environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
    // environment.production ? [] : NgxsLoggerPluginModule.forRoot(),
    NgxSoapModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    ContextService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

