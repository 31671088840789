import { State, Selector, Action, StateContext } from '@ngxs/store';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { Summary } from '../models/summary.model';
import { FetchSummary } from '../actions/summary.actions';

// State of the object Summary
export class SummaryStateModel {
    summary: Summary;
}

// Explicit State
@State<SummaryStateModel>({
    name: 'Summary',
    defaults: {
        summary: null,
    }
})

// Selectors and Reducers
export class SummaryState {
    constructor(private dataProvider: DataService) { }

    @Selector()
    static getSummary(state: SummaryStateModel) {
        return state.summary;
    }

    // Actions
    @Action(FetchSummary)
    getAll({ getState, patchState }: StateContext<SummaryStateModel>, { enterprise }: FetchSummary) {
        return this.dataProvider.getAll(`summary/${enterprise}`)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    summary: result['Value']
                });
            }));
    }
}