import { State, Selector, Action, StateContext } from '@ngxs/store';
import { FetchAnalyst, FetchAnalystList, CreateAnalyst, UpdateAnalyst, DeleteAnalyst, FetchDeliveryList, FetchAnalystListByEnterprise } from '../actions/analyst.actions';
import { Analyst } from '../models/analyst.model';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { ShowAlert } from '../actions/alert.actions';
import { Alert, AlertType } from '../models/alert.model';
import { AuthService } from '../services/auth.service';

// State of the object Analyst
export class AnalystStateModel {
    Analysts: Analyst[];
    actualAnalyst: Analyst;
    analystCreated: string;
}

// Explicit State
@State<AnalystStateModel>({
    name: 'Analysts',
    defaults: {
        Analysts: [],
        actualAnalyst: null,
        analystCreated: '',
    }
})

// Selectors and Reducers
export class AnalystState {
    constructor(private dataProvider: DataService, private auth: AuthService) { }

    // Selectors
    @Selector()
    static getAnalystList(state: AnalystStateModel) {
        return state.Analysts;
    }

    @Selector()
    static getAnalyst(state: AnalystStateModel) {
        return state.actualAnalyst;
    }

    @Selector()
    static getAnalystCreated(state: AnalystStateModel) {
        return state.analystCreated;
    }

    // Actions
    @Action(FetchAnalystList)
    getAll({ getState, patchState, dispatch }: StateContext<AnalystStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('analyst')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Analysts: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => { console.log(err); dispatch(new ShowAlert(new Alert("Error obteniendo analistas", err.error.Value.Message, AlertType.Error))) }));
    }

    @Action(FetchAnalystListByEnterprise)
    getAllByEnterprise({ getState, patchState, dispatch }: StateContext<AnalystStateModel>, { enterprise }: FetchAnalystListByEnterprise) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll(`analyst/enterprise/${enterprise}`)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Analysts: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => { err = err.error.errors[Object.keys(err.error.errors)[0]];; dispatch(new ShowAlert(new Alert("Error obteniendo analistas", err, AlertType.Error))) }));
    }

    @Action(FetchDeliveryList)
    getAllDelivery({ getState, patchState, dispatch }: StateContext<AnalystStateModel>) {
        return this.dataProvider.getAll('deliveries')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Analysts: result['Value']
                });
            }, (err) => { dispatch(new ShowAlert(new Alert("Error obteniendo entregas", err.error.Value.Message, AlertType.Error))) }));
    }

    @Action(FetchAnalyst)
    get({ getState, patchState, dispatch }: StateContext<AnalystStateModel>, { payload }: FetchAnalyst) {
        return this.dataProvider.getOne('analyst', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualAnalyst: result['Value']
                });
            }, (err) => { dispatch(new ShowAlert(new Alert("Error obteniendo analista", err.error.Value.Message, AlertType.Error))) }));
    }

    @Action(CreateAnalyst)
    create({ getState, patchState, dispatch }: StateContext<AnalystStateModel>, { payload }: CreateAnalyst) {
        const state = getState();
        return this.dataProvider.save('analyst', payload)
            .pipe(tap((result) => {
                patchState({
                    analystCreated: result['Value']
                });

                dispatch(new FetchAnalystListByEnterprise(this.auth.getUser().Enterprise));
            }, (err) => { dispatch(new ShowAlert(new Alert("Error creando analista", err.error.Value.Message, AlertType.Error))) }));
    }

    @Action(UpdateAnalyst)
    update({ getState, patchState, dispatch }: StateContext<AnalystStateModel>, { payload }: UpdateAnalyst) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.update('analyst', payload)
            .pipe(tap((result) => {
                dispatch(new SetOffLoadingState());
                if (result) {
                    const state = getState();
                    patchState({
                        Analysts: state.Analysts.map(Analyst => {
                            if (Analyst._id === payload._id) {
                                Analyst = payload;
                            }

                            return Analyst;
                        })
                    });
                }
            }, (err) => { dispatch(new SetOffLoadingState()); dispatch(new ShowAlert(new Alert("Error actualizando analista", err.error.Value.Message, AlertType.Error))) }));
    }

    @Action(DeleteAnalyst)
    delete({ getState, patchState, dispatch }: StateContext<AnalystStateModel>, { payload }: DeleteAnalyst) {
        return this.dataProvider.delete('analyst', payload)
            .pipe(tap((result) => {
                if (result['Value'] >= 1) {
                    const state = getState();
                    patchState({
                        Analysts: state.Analysts.filter(Analyst => Analyst._id !== payload)
                    });
                }
            }, (err) => { dispatch(new ShowAlert(new Alert("Error eliminando analista", err, AlertType.Error))) }));
    }
}