import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPhone'
})
export class SearchPhonePipe implements PipeTransform {

  transform(array: any[], text: string, colum1: string, colum2: string, colum3: string): any[] {
    if (text === '') {
      return array;
    }

    text = text.toLowerCase();

    return array.filter(item => {
      item = item[colum1] + item[colum2] + item[colum3];
      return item.toLowerCase().includes(text);
    });

  }
}
