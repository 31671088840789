import { Phone } from '../models/phone.model';

export class FetchPhoneList {
    static readonly type = '[Phone] Fetch List';
}

export class FetchPhone {
    static readonly type = '[Phone] Fetch';
    constructor(public payload: string) {}
}

export class CreatePhone {
    static readonly type = '[Phone] Create';
    constructor(public payload: Phone) {}
}

export class UpdatePhone {
    static readonly type = '[Phone] Update';
    constructor(public payload: Phone) {}
}

export class DeletePhone {
    static readonly type = '[Phone] Delete';
    constructor(public payload: string) {}
}
