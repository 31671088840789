import { Evaluation } from '../models/evaluation.model';
import { User } from '../models/user.model';

export class FetchEvaluationList {
    static readonly type = '[Evaluation] Fetch List';
}

export class FetchEvaluation {
    static readonly type = '[Evaluation] Fetch';
    constructor(public payload: string) {}
}

export class CreateEvaluation {
    static readonly type = '[Evaluation] Create';
    constructor(public payload: User) {}
}

export class UpdateEvaluation {
    static readonly type = '[Evaluation] Update';
    constructor(public payload: Evaluation) {}
}

export class DeleteEvaluation {
    static readonly type = '[Evaluation] Delete';
    constructor(public payload: string) {}
}
