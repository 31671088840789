import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [AuthGuard] },
  // tslint:disable-next-line: max-line-length
  // { path: 'customer-service', loadChildren: './pages/customer-service/customer-service.module#CustomerServicePageModule', canActivate: [AuthGuard] },
  // { path: 'brenntag', loadChildren: './pages/brenntag/brenntag.module#BrenntagPageModule', canActivate: [AuthGuard] },
  // { path: 'mappex', loadChildren: './pages/mappex/mappex.module#MappexPageModule', canActivate: [AuthGuard] },
  { path: 'rating', loadChildren: './pages/rating/rating.module#RatingPageModule', canActivate: [AuthGuard] },
  // { path: 'benefit-admin', loadChildren: './pages/benefit-admin/benefit-admin.module#BenefitAdminPageModule', canActivate: [AuthGuard] },
  // { path: 'benefit-process', loadChildren: './pages/benefit-process/benefit-process.module#BenefitProcessPageModule', canActivate: [AuthGuard] },
  { path: 'employee-admin', loadChildren: './pages/employee-admin/employee-admin.module#EmployeeAdminPageModule', canActivate: [AuthGuard] },
  // { path: 'my-benefit', loadChildren: './pages/my-benefit/my-benefit.module#MyBenefitPageModule', canActivate: [AuthGuard] },
  // { path: 'reset-password', loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule', canActivate: [AuthGuard] },
  // { path: 'phone', loadChildren: './pages/phone/phone.module#PhonePageModule', canActivate: [AuthGuard] },
  { path: 'new-password/:id', loadChildren: './pages/new-password/new-password.module#NewPasswordPageModule' },
  // { path: 'evaluation', loadChildren: './pages/performance/evaluation/evaluation.module#EvaluationPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
