export class Alert {
    Title: string;
    Message: string;
    Type: AlertType;

    constructor(title, message, type) {
        this.Title = title;
        this.Message = message;
        this.Type = type;

        if(!message) {
            this.Message = "Unknown Error";
        }
    }
}

export enum AlertType {
    Error = 'danger',
    Message = 'primary',
    Success = 'success',
    Warning = 'warning'
}