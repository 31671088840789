import { Contract } from '../models/contract.model';
import { Query } from '../models/query.model';

export class FetchContractList {
    static readonly type = '[Contract] Fetch List';
}

export class FetchECUContractList {
    static readonly type = '[Contract] ECU Fetch List';
}

export class FetchDeliveryList {
    static readonly type = '[Contract] Fetch Delivery List';
}

export class FetchContract {
    static readonly type = '[Contract] Fetch';

    constructor(public payload: string) {}
}

export class FetchContractForEditing {
    static readonly type = '[Contract] Fetch for Editing';

    constructor(public payload: string) {}
}

export class FetchContractBy {
    static readonly type = '[Contract By] Fetch';

    constructor(public payload: Query) {}
}

export class CreateContract {
    static readonly type = '[Contract] Create';
    constructor(public payload: Contract) {}
}

export class UpdateContract {
    static readonly type = '[Contract] Update';

    constructor(public payload: Contract) {}
}

export class ModifyContract {
    static readonly type = '[Contract] Modify';

    constructor(public payload: Contract) {}
}

export class ModifyContractForEditing {
    static readonly type = '[Contract] Modify for Editing';

    constructor(public payload: Contract) {}
}

export class DeleteContract {
    static readonly type = '[Contract] Delete';

    constructor(public payload: string) {}
}