import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Evaluation } from '../models/evaluation.model';
import { FetchEvaluation, FetchEvaluationList, CreateEvaluation, UpdateEvaluation, DeleteEvaluation } from '../actions/evaluation.actions';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { tap } from 'rxjs/operators';
import { ShowAlert } from '../actions/alert.actions';
import { AlertType, Alert } from '../models/alert.model';
import { DataService } from '../services/data.service';

// State of the object Evaluation
export class EvaluationStateModel {
    evaluations: Evaluation[];
    actualEvaluation: Evaluation;
    evaluationCreate: string;
}

// Explicit State
@State<EvaluationStateModel>({
    name: 'Evaluations',
    defaults: {
        evaluations: [],
        actualEvaluation: null,
        evaluationCreate: '',
    }
})

// Selectors and Reducers
export class EvaluationState {
    constructor(private dataProvider: DataService) { }

    // Selectors
    @Selector()
    static getEvaluationList(state: EvaluationStateModel) {
        return state.evaluations;
    }

    @Selector()
    static getEvaluation(state: EvaluationStateModel) {
        return state.actualEvaluation;
    }
    @Selector()
    static getEvaluationCreated(state: EvaluationStateModel) {
        return state.evaluationCreate;
    }

    @Action(FetchEvaluationList)
    getAll({ getState, patchState, dispatch }: StateContext<EvaluationStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('evaluation')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    evaluations: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(FetchEvaluation)
    get({ getState, patchState, dispatch }: StateContext<EvaluationStateModel>, { payload }: FetchEvaluation) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('evaluation', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualEvaluation: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => {
                err = err.error.errors[Object.keys(err.error.errors)[0]];
                dispatch(new ShowAlert(new Alert('Error obteniendo usuario', err, AlertType.Error)));
            }));
    }

    @Action(CreateEvaluation)
    create({ getState, patchState, dispatch }: StateContext<EvaluationStateModel>, { payload }: CreateEvaluation) {
        const state = getState();
        return this.dataProvider.save('user', payload)
            .pipe(tap((result) => {
                patchState({
                    evaluationCreate: result['Value']
                });

                dispatch(new FetchEvaluationList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(UpdateEvaluation)
    update({ getState, patchState, dispatch }: StateContext<EvaluationStateModel>, { payload }: UpdateEvaluation) {
        const state = getState();
        return this.dataProvider.update('evaluation', payload)
            .pipe(tap((result) => {
                patchState({
                    evaluations: state.evaluations.map(evaluation => {
                        if (evaluation._id === payload._id) {
                            evaluation = payload;
                        }

                        return evaluation;
                    })
                });

                dispatch(new FetchEvaluationList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(DeleteEvaluation)
    delete({ getState, patchState, dispatch }: StateContext<EvaluationStateModel>, { payload }: DeleteEvaluation) {
        const state = getState();
        return this.dataProvider.delete('evaluation', payload)
            .pipe(tap((result) => {
                patchState({
                    evaluationCreate: result['Value']
                });
                dispatch(new ShowAlert(new Alert('Alerta', 'El beneficio ha sido eliminado', AlertType.Message)));
                dispatch(new FetchEvaluationList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }
}
