import { Analyst } from '../models/analyst.model';

export class FetchAnalystList {
    static readonly type = '[Analyst] Fetch List';
}

export class FetchAnalystListByEnterprise {
    static readonly type = '[Analyst] Fetch List By Enterprise';
    constructor(public enterprise: string) {}
}

export class FetchDeliveryList {
    static readonly type = '[Analyst] Fetch Delivery List';
}

export class FetchAnalyst {
    static readonly type = '[Analyst] Fetch';

    constructor(public payload: string) {}
}

export class CreateAnalyst {
    static readonly type = '[Analyst] Create';
    constructor(public payload: Analyst) {}
}

export class UpdateAnalyst {
    static readonly type = '[Analyst] Update';

    constructor(public payload: Analyst) {}
}

export class DeleteAnalyst {
    static readonly type = '[Analyst] Delete';

    constructor(public payload: string) {}
}