import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { tap } from 'rxjs/operators';
import { ShowAlert } from '../actions/alert.actions';
import { AlertType, Alert } from '../models/alert.model';
import { DataService } from '../services/data.service';
import { FetchPhoneList, FetchPhone, CreatePhone, UpdatePhone, DeletePhone } from '../actions/phone.action';
import { Phone } from '../models/phone.model';

// State of the object Ticket
export class PhoneStateModel {
    phones: Phone[];
    actualPhone: Phone;
    phoneCreate: string;
}

// Explicit State
@State<PhoneStateModel>({
    name: 'Phones',
    defaults: {
        phones: [],
        actualPhone: null,
        phoneCreate: '',
    }
})

// Selectors and Reducers
export class PhoneState {
    constructor(private dataProvider: DataService) { }

    // Selectors
    @Selector()
    static getPhoneList(state: PhoneStateModel) {
        return state.phones;
    }

    @Selector()
    static getPhone(state: PhoneStateModel) {
        return state.actualPhone;
    }
    @Selector()
    static getPhoneCreated(state: PhoneStateModel) {
        return state.phoneCreate;
    }

    @Action(FetchPhoneList)
    getAll({ getState, patchState, dispatch }: StateContext<PhoneStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('phone')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    phones: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(FetchPhone)
    get({ getState, patchState, dispatch }: StateContext<PhoneStateModel>, { payload }: FetchPhone) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('phone', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualPhone: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => {
                err = err.error.errors[Object.keys(err.error.errors)[0]];
                dispatch(new ShowAlert(new Alert('Error obteniendo usuario', err, AlertType.Error)));
            }));
    }

    @Action(CreatePhone)
    create({ getState, patchState, dispatch }: StateContext<PhoneStateModel>, { payload }: CreatePhone) {
        const state = getState();
        return this.dataProvider.save('phone', payload)
            .pipe(tap((result) => {
                patchState({
                    phoneCreate: result['Value']
                });

                dispatch(new FetchPhoneList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(UpdatePhone)
    update({ getState, patchState, dispatch }: StateContext<PhoneStateModel>, { payload }: UpdatePhone) {
        const state = getState();
        return this.dataProvider.update('phone', payload)
            .pipe(tap((result) => {
                patchState({
                    phones: state.phones.map(Phone => {
                        if (Phone._id === payload._id) {
                            Phone = payload;
                        }

                        return Phone;
                    })
                });

                dispatch(new FetchPhoneList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(DeletePhone)
    delete({ getState, patchState, dispatch }: StateContext<PhoneStateModel>, { payload }: DeletePhone) {
        const state = getState();
        return this.dataProvider.delete('phone', payload)
            .pipe(tap((result) => {
                patchState({
                    phoneCreate: result['Value']
                });
                dispatch(new ShowAlert(new Alert('Alerta', 'Ha sido eliminado', AlertType.Message)));
                dispatch(new FetchPhoneList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }
}
