import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  // logout logs out an user from system
  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('user');
    localStorage.removeItem('enterprise');
    localStorage.removeItem('role');
    localStorage.removeItem('id');
    localStorage.removeItem('session_id');
  }

  // loggedIn verifies if an user is logged in the system
  loggedIn() {
    return localStorage.getItem('id_token') !== null;
  }

  getToken() {
    return localStorage.getItem('id_token');
  }

  // getUser gets username
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  // getUserID gets user ID
  getUserID() {
    return localStorage.getItem('id');
  }

  // getRole gets user role
  getRole() {
    return localStorage.getItem('role');
  }

  // getEnterprise gets user enterprise code
  getEnterprise() {
    return localStorage.getItem('enterprise');
  }

  // getSessionID gets session ID
  getSessionID() {
    return localStorage.getItem('session_id');
  }

  // getInitialPage gets the startup page for user
  getInitialPage() {
    return localStorage.getItem('initial_page');
  }
}
