import { environment } from '../environments/environment';

export class Globals {
    // uriBase is the url to back end server
    public static ecuUriBase = environment.ecuUriBase;
    public static primoUriBase = environment.primoUriBase;
    
    // empty string
    public static emptyString = "";
}

export class Auth {
    // Var to manage loggedin user
    public static user: any;
    public static enterprise: string;
    public static setUser(actualUser: any, actualEnterprise: string) {
        Auth.user = actualUser;
        Auth.enterprise = actualEnterprise;
    }
}

export class Utils {
    public static YYYYMMDD(date: Date): string {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm,
        (dd > 9 ? '' : '0') + dd
        ].join('-');
    };

    public static YYYYMM(date: Date): string {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [date.getFullYear(),
        (mm > 9 ? '' : '0') + mm
        ].join('-');
    };

    public static YYYY(date: Date): string {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return date.getFullYear().toString();
    };

    public static UUIDV4() {
        const foo: any = [1e7];
        return (foo + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }
}