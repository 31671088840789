import { Field } from '../models/field.model';

export class FetchFieldList {
    static readonly type = '[Field] Fetch List';
}

export class FetchField {
    static readonly type = '[Field] Fetch';
}

export class CreateField {
    static readonly type = '[Field] Create';
    constructor(public payload: Field) {}
}

export class UpdateField {
    static readonly type = '[Field] Update';

    constructor(public payload: Field) {}
}

export class DeleteField {
    static readonly type = '[Field] Delete';

    constructor(public payload: string) {}
}