import { Process } from '../models/process.model';

export class FetchProcessList {
    static readonly type = '[Process] Fetch List';
}

export class FetchProcess {
    static readonly type = '[Process] Fetch';
    constructor(public payload: string) { }
}

export class CreateProcess {
    static readonly type = '[Process] Create';
    constructor(public payload: Process) { }
}

export class UpdateProcess {
    static readonly type = '[Process] Update';
    constructor(public payload: Process) { }
}

export class DeleteProcess {
    static readonly type = '[Process] Delete';
    constructor(public payload: string) {}
}
