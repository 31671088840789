import { State, Selector, Action, StateContext } from '@ngxs/store';
import { FetchQueryList, UpdateQuery } from '../actions/query.actions';
import { Query } from '../models/query.model';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { ShowAlert } from '../actions/alert.actions';
import { Alert, AlertType } from '../models/alert.model';

// State of the object Query
export class QueryStateModel {
    Querys: Query[];
    actualQuery: Query;
    queryCreated: string;
}

// Explicit State
@State<QueryStateModel>({
    name: 'Querys',
    defaults: {
        Querys: [],
        actualQuery: null,
        queryCreated: '',
    }
})

// Selectors and Reducers
export class QueryState {
    constructor(private dataProvider: DataService) { }

    // Selectors
    @Selector()
    static getQueryList(state: QueryStateModel) {
        return state.Querys;
    }

    // Actions
    @Action(FetchQueryList)
    getAll({ getState, patchState, dispatch }: StateContext<QueryStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('query')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Querys: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => { console.log(err); dispatch(new ShowAlert(new Alert("Error obteniendo analistas", err.error.Value.Message, AlertType.Error))) }));
    }

    @Action(UpdateQuery)
    update({ getState, patchState, dispatch }: StateContext<QueryStateModel>, { payload }: UpdateQuery) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.update('query', payload)
            .pipe(tap((result) => {
                dispatch(new SetOffLoadingState());
                if (result) {
                    const state = getState();
                    patchState({
                        Querys: state.Querys.map(Query => {
                            if (Query._id === payload._id) {
                                Query = payload;
                            }

                            return Query;
                        })
                    });
                }
            }, (err) => { dispatch(new SetOffLoadingState()); dispatch(new ShowAlert(new Alert("Error actualizando analista", err.error.Value.Message, AlertType.Error))) }));
    }
}