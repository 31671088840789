import { Injectable, Inject } from '@angular/core';
import { DomController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';

interface Theme {
  name: string;
  styles: ThemeStyle[];
}

interface ThemeStyle {
  themeVariable: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeSwitcherService {
  private themes: Theme[] = [];
  private currentTheme: number = 0;

  constructor(private domCtrl: DomController, @Inject(DOCUMENT) private document: Document) {
    this.themes = [
      {
        name: 'default',
        styles: [
          // LFS primary colors
          { themeVariable: '--lfs-primary', value: '#C23032' },
          { themeVariable: '--lfs-secondary', value: '#EAB132' },
          { themeVariable: '--lfs-success', value: '#32db64' },
          { themeVariable: '--lfs-danger', value: '#c23032' },
          { themeVariable: '--lfs-warning', value: '#ffc107' },
          { themeVariable: '--lfs-reprocess', value: '#ff8000' },
          { themeVariable: '--lfs-light', value: '#F4F4F4' },
          { themeVariable: '--lfs-dark', value: '#404150' },
          { themeVariable: '--lfs-font-light', value: '#F4F4F4' },
          { themeVariable: '--lfs-font-dark', value: '#3E3E3E' },
          { themeVariable: '--lfs-bg', value: '#535468' },
          { themeVariable: '--lfs-table', value: '#EEE' },
          { themeVariable: '--lfs-navbar', value: '#3d3e4e' },
          { themeVariable: '--lfs-contrast', value: '#2B2D3C' },
          { themeVariable: '--lfs-font', value: '#f4f4f4' },
          { themeVariable: '--lfs-input', value: '#e0e0e0' },
          { themeVariable: '--lfs-border', value: 'rgba(255,255,255,0.5)' },
          { themeVariable: '--lfs-header', value: '#414152' },
          { themeVariable: '--lfs-content', value: '#2F3243' },
        ]
      },
      {
        name: 'light',
        styles: [
          { themeVariable: '--lfs-primary', value: '#2196f3' },
          { themeVariable: '--lfs-secondary', value: '#acb0c4' },
          { themeVariable: '--lfs-success', value: '#32db64' },
          { themeVariable: '--lfs-danger', value: '#c23032' },
          { themeVariable: '--lfs-warning', value: '#ffc107' },
          { themeVariable: '--lfs-reprocess', value: '#ff8000' },
          { themeVariable: '--lfs-light', value: '#f4f4f4' },
          { themeVariable: '--lfs-dark', value: '#2a2d3d' },
          { themeVariable: '--lfs-font-light', value: '#3E3E3E' },
          { themeVariable: '--lfs-font-dark', value: '#3E3E3E' },
          { themeVariable: '--lfs-bg', value: '#2D3140' },
          { themeVariable: '--lfs-navbar', value: '#2f3243' },
          { themeVariable: '--lfs-contrast', value: '#2B2D3C' },
          { themeVariable: '--lfs-font', value: '#f4f4f4' },
          { themeVariable: '--lfs-input', value: '#41435a' },
          { themeVariable: '--lfs-header', value: '#3A4053' },
          { themeVariable: '--lfs-content', value: '#2F3243' },
        ]
      }
    ];
  }

  cycleTheme(): void {
    if (this.themes.length > this.currentTheme + 1) {
      this.currentTheme++;
    } else {
      this.currentTheme = 0;
    }
    this.setTheme(this.themes[this.currentTheme].name);
  }

  setTheme(name: string): void {
    let theme = this.themes.find(theme => theme.name === name);
    this.domCtrl.write(() => {
      theme.styles.forEach(style => {
        this.document.documentElement.style.setProperty(style.themeVariable, style.value);
      });
    });
  }
}