import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';

// State of the loading component
export class LoadingStateModel {
    isLoading: boolean;
}

// Explicit State
@State<LoadingStateModel>({
    name: 'Loading',
    defaults: {
        isLoading: false,
    }
})

// Selectors and Reducers
export class LoadingState {
    constructor() { }

    // Selectors
    @Selector()
    static getLoadingState(state: LoadingStateModel) {
        return state.isLoading;
    }

    // Actions
    @Action(SetOnLoadingState)
    loadingOn({ patchState }: StateContext<LoadingStateModel>) {
        patchState({
            isLoading: true
        });
    }

    @Action(SetOffLoadingState)
    loadingOff({ patchState }: StateContext<LoadingStateModel>) {
        patchState({
            isLoading: false
        });
    }
    
}