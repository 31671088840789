import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(array: any[], text: string, firstName: string, secondName: string, FirstLastName: string, SecondLastName: string): any[] {
    if (text === '') {
      return array;
    }

    text = text.toLowerCase();

    return array.filter(item => {
      item = item[firstName] + ' ' + item[secondName] + ' ' + item[FirstLastName] + ' ' + item[SecondLastName];
      return item.toLowerCase().includes(text);
    });

  }

}
