import { Query } from '../models/query.model';

export class FetchQueryList {
    static readonly type = '[Query] Fetch Query List';
}

export class UpdateQuery {
    static readonly type = '[Query] Update';

    constructor(public payload: Query) {}
}