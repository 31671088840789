import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  constructor() {
    this.setContext('primo');
  }

  public isPrimoContext() {
    return localStorage.getItem('context') === 'primo';
  }

  public isEcuContext() {
    return localStorage.getItem('context') === 'ecu';
  }

  public setContext(context: string) {
    localStorage.setItem('context', context);
  }
}
