import { NgModule } from '@angular/core';
import { SearchPipe } from './search/search.pipe';
import { SearchPhonePipe } from './search-phone/search-phone.pipe';

@NgModule({
  declarations: [SearchPipe, SearchPhonePipe],
  exports: [SearchPipe, SearchPhonePipe]
})
export class PipesModule { }

