import { State, Selector, Action, StateContext } from '@ngxs/store';
import { FetchProcessList, FetchProcess, CreateProcess, DeleteProcess, UpdateProcess} from '../actions/process.actions';
import { Process } from '../models/process.model';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { ShowAlert } from '../actions/alert.actions';
import { Alert, AlertType } from '../models/alert.model';

// State of the object Process
export class ProcessStateModel {
    process: Process[];
    actualProcess: Process;
    processCreate: string;
}

// Explicit State
@State<ProcessStateModel>({
    name: 'Process',
    defaults: {
        process: [],
        actualProcess: null,
        processCreate: '',
    }
})

// Selectors and Reducers
export class ProcessState {
    constructor(private dataProvider: DataService) { }

    // Selectors
    @Selector()
    static getProcessList(state: ProcessStateModel) {
        return state.process;
    }
    @Selector()
    static getProcess(state: ProcessStateModel) {
        return state.actualProcess;
    }
    @Selector()
    static getProcessCreated(state: ProcessStateModel) {
        return state.processCreate;
    }

    // Actions
    @Action(FetchProcessList)
    getAll({ getState, patchState, dispatch }: StateContext<ProcessStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('process')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    process: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(FetchProcess)
    get({ getState, patchState, dispatch }: StateContext<ProcessStateModel>, { payload }: FetchProcess) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('process', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualProcess: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(CreateProcess)
    create({ getState, patchState, dispatch }: StateContext<ProcessStateModel>, { payload }: CreateProcess) {
        const state = getState();
        return this.dataProvider.save('process', payload)
            .pipe(tap((result) => {
                patchState({
                    processCreate: result['Value']
                });

                dispatch(new FetchProcessList());
            }));
    }

    @Action(UpdateProcess)
    update({ getState, patchState, dispatch }: StateContext<ProcessStateModel>, { payload }: UpdateProcess) {
        const state = getState();
        return this.dataProvider.update('process', payload)
            .pipe(tap((result) => {
                patchState({
                    process: state.process.map(process => {
                        if (process._id === payload._id) {
                            process = payload;
                        }

                        return process;
                    })
                });

                dispatch(new FetchProcessList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }


    @Action(DeleteProcess)
    delete({ getState, patchState, dispatch }: StateContext<ProcessStateModel>, { payload }: DeleteProcess) {
        const state = getState();
        return this.dataProvider.delete('process', payload)
            .pipe(tap((result) => {
                patchState({
                    processCreate: result['Value']
                });

                dispatch(new FetchProcessList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

}
