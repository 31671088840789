import { State, Selector, Action, StateContext, Select } from '@ngxs/store';
import { FetchUserList, CreateUser, GetLoggedUser, DeleteUser, UpdateUser, FetchUser, ChangePassword, FetchTotalUsers, FetchUserByName, requestRecovery, RecoveryPassword } from '../actions/user.actions';
import { User } from '../models/user.model';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { AuthService } from '../services/auth.service';
import { ShowAlert } from '../actions/alert.actions';
import { Alert, AlertType } from '../models/alert.model';

// State of the object User
export class UserStateModel {
    Users: User[];
    totalUsers: number;
    actualUser: User;
    loggedUser: User;
    userCreated: string;
    passwordWasChanged: boolean;
    requestPasword: boolean;
    recoveryPassword: boolean;
}

// Explicit State
@State<UserStateModel>({
    name: 'Users',
    defaults: {
        Users: [],
        totalUsers: 0,
        actualUser: null,
        loggedUser: null,
        userCreated: '',
        passwordWasChanged: false,
        requestPasword: false,
        recoveryPassword: false
    }
})

// Selectors and Reducers
export class UserState {
    constructor(private dataProvider: DataService, private auth: AuthService) { }

    // Selectors
    @Selector()
    static getUserList(state: UserStateModel) {
        return state.Users;
    }

    // Selectors
    @Selector()
    static getTotalUsers(state: UserStateModel) {
        return state.totalUsers;
    }

    @Selector()
    static getUser(state: UserStateModel) {
        return state.actualUser;
    }

    @Selector()
    static getLoggedUser(state: UserStateModel) {
        return state.loggedUser;
    }

    @Selector()
    static getUserCreated(state: UserStateModel) {
        return state.userCreated;
    }

    @Selector()
    static getPasswordWasChanged(state: UserStateModel) {
        return state.passwordWasChanged;
    }
    @Select()
    static getRecoveryPassword() {

    }

    // Actions
    @Action(GetLoggedUser)
    getLoggedUser({ patchState }: StateContext<UserStateModel>) {
        patchState({
            loggedUser: this.auth.getUser(),
            passwordWasChanged: false,
        });
    }

    @Action(FetchUserList)
    getAll({ getState, patchState, dispatch }: StateContext<UserStateModel>, { limit, skip }: FetchUserList) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAllWithLimit('user', limit, skip)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Users: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(FetchTotalUsers)
    getTotal({ getState, patchState }: StateContext<UserStateModel>) {
        return this.dataProvider.getTotal('user')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    totalUsers: result['Value']
                });
            }));
    }

    @Action(FetchUser)
    get({ getState, patchState, dispatch }: StateContext<UserStateModel>, { payload }: FetchUser) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('user', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualUser: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => {
                err = err.error.errors[Object.keys(err.error.errors)[0]];
                dispatch(new ShowAlert(new Alert('Error obteniendo usuario', err, AlertType.Error)));
            }));
    }

    @Action(FetchUserByName)
    getByName({ getState, patchState, dispatch }: StateContext<UserStateModel>, { name }: FetchUserByName) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.save('user/get', {
            "Fields": ["FullName"],
            "Values": [name]
        })
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Users: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => {
                err = err.error.errors[Object.keys(err.error.errors)[0]];
                dispatch(new ShowAlert(new Alert('Error obteniendo usuario', err, AlertType.Error)));
            }));
    }

    @Action(CreateUser)
    create({ getState, patchState, dispatch }: StateContext<UserStateModel>, { payload }: CreateUser) {
        const state = getState();
        return this.dataProvider.save('user', payload)
            .pipe(tap((result) => {
                patchState({
                    userCreated: result['Value']
                });

                dispatch(new FetchUserList(0, 0));
            }));
    }

    @Action(UpdateUser)
    update({ getState, patchState, dispatch }: StateContext<UserStateModel>, { payload }: UpdateUser) {
        const state = getState();
        return this.dataProvider.update('user', payload)
            .pipe(tap((result) => {
                patchState({
                    Users: state.Users.map(user => {
                        if (user._id === payload._id) {
                            user = payload;
                        }

                        return user;
                    })
                });

                dispatch(new FetchUserList(0, 0));
            }));
    }

    @Action(DeleteUser)
    delete({ getState, patchState, dispatch }: StateContext<UserStateModel>, { payload }: DeleteUser) {
        const state = getState();
        return this.dataProvider.delete('user', payload)
            .pipe(tap((result) => {
                patchState({
                    userCreated: result['Value']
                });

                dispatch(new FetchUserList(0, 0));
            }));
    }

    @Action(ChangePassword)
    changePassword({ getState, patchState, dispatch }: StateContext<UserStateModel>,
        { username, oldPassword, newPassword }: ChangePassword) {
        dispatch(new SetOnLoadingState());
        const state = getState();
        return this.dataProvider.saveBy('auth', username, oldPassword, newPassword)
            .pipe(tap((result) => {
                patchState({
                    passwordWasChanged: result['Value']
                });
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert('Aviso', 'La contraseña fue modificada', AlertType.Message)));
            }, err => {
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert('Error', err.error.Message, AlertType.Error)));
            }));
    }

    @Action(requestRecovery)
    requestRecovery({ getState, patchState, dispatch }: StateContext<UserStateModel>,
        { email }: requestRecovery) {
        debugger;
        dispatch(new SetOnLoadingState);
        const state = getState();
        return this.dataProvider.requestRecovery('auth', email)
            .pipe(tap((result) => {
                patchState({
                    requestPasword: result['Value']
                });
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert('Aviso', 'Se ha realizado la solicitud de cambio de contraseña, verifica tú correo electronico y sigue los pasos.', AlertType.Message)));
            }, err => {
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert('Error', err.error.Message, AlertType.Error)));
            }));
    }

    @Action(RecoveryPassword)
    getRecoveryPassword({ getState, patchState, dispatch }: StateContext<UserStateModel>,
        { id, newPassword, confirmNewPassword }: RecoveryPassword) {
            debugger;
        dispatch(new SetOnLoadingState());
        const state = getState();
        return this.dataProvider.saveNewPassword('auth', id, newPassword, confirmNewPassword)
            .pipe(tap((result) => {
                patchState({
                    recoveryPassword: result['Value']
                });
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert('Aviso', 'La contraseña fue modificada', AlertType.Message)));
            }, err => {
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert('Error', err.error.Message, AlertType.Error)));
            }));
    }


}