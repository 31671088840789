import { State, Selector, Action, StateContext } from '@ngxs/store';
import { ShowAlert as ShowAlert } from '../actions/alert.actions';
import { Alert } from '../models/alert.model';

// State of the object Error
export class AlertStateModel {
    alert: Alert;
}

// Explicit State
@State<AlertStateModel>({
    name: 'Alerts',
    defaults: {
        alert: null,
    }
})

// Selectors and Reducers
export class AlertState {
    constructor() { }

    @Selector()
    static getAlert(state: AlertStateModel) {
        return state.alert;
    }

    @Action(ShowAlert)
    create({ patchState }: StateContext<AlertStateModel>, { payload }: ShowAlert) {
        patchState({
            alert: payload
        })
    }
}