import { Component, Input } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ThemeSwitcherService } from './services/theme-switcher.service';
import { Router } from '@angular/router';
import { LoadingComponent } from './components/_ui/loading/loading.component';
import { AlertComponent } from './components/_ui/alert/alert.component';
import { AuthService } from './services/auth.service';
import { Select, Store } from '@ngxs/store';
import { UserState } from './states/user.state';
import { Observable } from 'rxjs';
import { GetLoggedUser } from './actions/user.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  entryComponents: [LoadingComponent, AlertComponent]
})
export class AppComponent {
  @Select(UserState.getLoggedUser) user$: Observable<string>;
  user: any;
  @Input() src: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private themeSwitcher: ThemeSwitcherService,
    private menuCtrl: MenuController,
    private router: Router,
    private store: Store,
    private auth: AuthService,
  ) {
    this.initializeApp();
  }

  closeMenu() {
    this.menuCtrl.toggle()
  }

  go(url: string) {
    this.router.navigateByUrl(url);
    this.closeMenu();
  }
  redirect(url: string) {
    window.open(url);
  }

  // onLogout logs out an user
  onLogout() {
    this.auth.logout();
    this.go("/");
  }

  contains(roles, role) {
    if (roles && role) {
      return (roles.indexOf(role) > -1);
    }

    if (!roles) return true;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.themeSwitcher.setTheme("default");
      this.store.dispatch(new GetLoggedUser());
      this.user$.subscribe(data => {
        if (data) {
          this.user = data;
          this.src = `assets/photo/${this.user._id}.webp`;
        }
      })
    });
  }
}
