import { State, Action, StateContext } from '@ngxs/store';
import { SendEmail } from '../actions/email.actions';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { SetOffLoadingState } from '../actions/loading.actions';
import { ShowAlert } from '../actions/alert.actions';
import { Alert, AlertType } from '../models/alert.model';

// State of the object Field
export class EmailStateModel {
    email: string;
}

// Explicit State
@State<EmailStateModel>({
    name: 'Email',
    defaults: {
        email: null,
    }
})

// Selectors and Reducers
export class EmailState {
    constructor(private dataProvider: DataService) { }

    @Action(SendEmail)
    send({ patchState, dispatch }: StateContext<EmailStateModel>, { analystList, enterprise }: SendEmail) {
        return this.dataProvider.save(`email/analyst/${enterprise}`, analystList)
            .pipe(tap((result) => {
                patchState({
                    email: result['Value']
                });
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert("Enviado", `El correo fue enviado correctamente`, AlertType.Message)));
            }, (err) => {
                dispatch(new SetOffLoadingState());
                dispatch(new ShowAlert(new Alert("Error", `Hubo un error enviando el correo ${err.error.Message}`, AlertType.Error)));
            }));
    }
}