import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Benefit } from '../models/benefit.model';
import { FetchBenefit, FetchBenefitList, CreateBenefit, UpdateBenefit, DeleteBenefit } from '../actions/benefit.actions';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { tap } from 'rxjs/operators';
import { ShowAlert } from '../actions/alert.actions';
import { AlertType, Alert } from '../models/alert.model';
import { DataService } from '../services/data.service';

// State of the object Ticket
export class BenefitStateModel {
    benefits: Benefit[];
    actualBenefit: Benefit;
    benefitCreate: string;
}

// Explicit State
@State<BenefitStateModel>({
    name: 'Benefits',
    defaults: {
        benefits: [],
        actualBenefit: null,
        benefitCreate: '',
    }
})

// Selectors and Reducers
export class BenefitState {
    constructor(private dataProvider: DataService) { }

    // Selectors
    @Selector()
    static getBenefitList(state: BenefitStateModel) {
        return state.benefits;
    }

    @Selector()
    static getBenefit(state: BenefitStateModel) {
        return state.actualBenefit;
    }
    @Selector()
    static getBenefitCreated(state: BenefitStateModel) {
        return state.benefitCreate;
    }

    @Action(FetchBenefitList)
    getAll({ getState, patchState, dispatch }: StateContext<BenefitStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('benefit')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    benefits: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(FetchBenefit)
    get({ getState, patchState, dispatch }: StateContext<BenefitStateModel>, { payload }: FetchBenefit) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('benefit', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualBenefit: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, (err) => {
                err = err.error.errors[Object.keys(err.error.errors)[0]];
                dispatch(new ShowAlert(new Alert('Error obteniendo usuario', err, AlertType.Error)));
            }));
    }

    @Action(CreateBenefit)
    create({ getState, patchState, dispatch }: StateContext<BenefitStateModel>, { payload }: CreateBenefit) {
        const state = getState();
        return this.dataProvider.save('benefit', payload)
            .pipe(tap((result) => {
                patchState({
                    benefitCreate: result['Value']
                });

                dispatch(new FetchBenefitList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(UpdateBenefit)
    update({ getState, patchState, dispatch }: StateContext<BenefitStateModel>, { payload }: UpdateBenefit) {
        const state = getState();
        return this.dataProvider.update('benefit', payload)
            .pipe(tap((result) => {
                patchState({
                    benefits: state.benefits.map(benefit => {
                        if (benefit._id === payload._id) {
                            benefit = payload;
                        }

                        return benefit;
                    })
                });

                dispatch(new FetchBenefitList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());

            }));
    }

    @Action(DeleteBenefit)
    delete({ getState, patchState, dispatch }: StateContext<BenefitStateModel>, { payload }: DeleteBenefit) {
        const state = getState();
        return this.dataProvider.delete('benefit', payload)
            .pipe(tap((result) => {
                patchState({
                    benefitCreate: result['Value']
                });
                dispatch(new ShowAlert(new Alert('Alerta', 'El beneficio ha sido eliminado', AlertType.Message)));
                dispatch(new FetchBenefitList());
            }, err => {
                dispatch(new ShowAlert(new Alert('Error', err.message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }
}
