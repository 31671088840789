import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { LoadingState } from 'src/app/states/loading.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Select(LoadingState.getLoadingState) loading$: Observable<boolean>;

  constructor(private store: Store) { }

  ngOnInit() {}
}
