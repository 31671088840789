import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Field } from '../models/field.model';
import { FetchField, FetchFieldList, CreateField, UpdateField, DeleteField } from '../actions/field.actions';

// State of the object Field
export class FieldStateModel {
    Fields: Field[];
    actualField: Field;
}

// Explicit State
@State<FieldStateModel>({
    name: 'Fields',
    defaults: {
        Fields: [],
        actualField: null,
    }
})

// Selectors and Reducers
export class FieldState {
    constructor() { }

    // Selectors
    @Selector()
    static getFieldList(state: FieldStateModel) {
        return state.Fields;
    }

    @Selector()
    static getField(state: FieldStateModel) {
        return state.actualField;
    }

    // Actions
    @Action(FetchFieldList)
    getAll({ getState, patchState }: StateContext<FieldStateModel>) {
        const state = getState();
        patchState({
            ...state,
            Fields: state.Fields
        })
    }

    @Action(FetchField)
    get({ getState, patchState }: StateContext<FieldStateModel>) {
        const state = getState();
        patchState({
            ...state,
            actualField: state.actualField
        })
    }

    @Action(CreateField)
    create({ getState, patchState }: StateContext<FieldStateModel>, { payload }: CreateField) {
        const state = getState();
        patchState({
            Fields: [...state.Fields, payload]
        })
    }

    @Action(UpdateField)
    update({ getState, patchState }: StateContext<FieldStateModel>, { payload }: UpdateField) {
        const state = getState();
        patchState({
            Fields: state.Fields.map(Field => {
                if (Field.ID === payload.ID) {
                    Field = payload;
                }

                return Field;
            })
        })
    }

    @Action(DeleteField)
    delete({ getState, patchState }: StateContext<FieldStateModel>, { payload }: DeleteField) {
        const state = getState();
        patchState({
            Fields: state.Fields.filter(Field => Field.ID !== payload)
        })
    }
}