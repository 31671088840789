import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Options } from './options';
import { Globals } from '../app.globals';
import { ContextService } from './context.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(public http: HttpClient, private contextService: ContextService) {
  }

  private getUriBase() {
    return this.contextService.isPrimoContext() ? Globals.primoUriBase : Globals.ecuUriBase;
  }

  getOne(endpoint: string, id: string) {
    return this.http.get<any>(this.getUriBase() + endpoint + `/${id}`, { headers: this.getHeaders() });
  }

  getAll(endpoint: string) {
    return this.http.get<any[]>(this.getUriBase() + endpoint, { headers: this.getHeaders() });
  }

  getAllWithLimit(endpoint: string, limit: number, skip: number) {
    return this.http.get<any[]>(this.getUriBase() + endpoint + `/${limit}/${skip}`, { headers: this.getHeaders() });
  }

  getTotal(endpoint: string) {
    return this.http.get<any[]>(this.getUriBase() + endpoint + "/total", { headers: this.getHeaders() });
  }

  login(options: Options) {
    const endpoint = this.contextService.isPrimoContext() ? 'auth' : `user/login/${options.Model.Username}/${options.Model.Password}`;

    if (this.contextService.isPrimoContext())
      return this.http.post(this.getUriBase() + endpoint, options.Model, { headers: this.getHeaders() });

    return this.http.get(this.getUriBase() + endpoint, { headers: this.getHeaders() });
  }

  logout(options: Options) {
    return this.http.post(this.getUriBase() + 'out', options, { headers: this.getHeaders() });
  }

  save(endpoint: string, obj: any) {
    return this.http.post<any>(this.getUriBase() + endpoint, obj, { headers: this.getHeaders() });
  }

  saveBy(endpoint: string, ...passwords) {
    return this.http.post<any>(this.getUriBase() + endpoint + `/${passwords[0]}/${passwords[1]}/${passwords[2]}`, { headers: this.getHeaders() });
  }

  saveNewPassword(endpoint: string, id: string, newPassword, confirmNewPassword: string) {
    debugger;
    return this.http.post<any>(this.getUriBase() + endpoint + "/change" + `/${id}/${newPassword}/${confirmNewPassword}`, { headers: this.getHeaders() });
  }

  requestRecovery(endpoint: string, email: string) {
    debugger;
    return this.http.post<any>(this.getUriBase() + endpoint + `/${email}`, { headers: this.getHeaders() });
  }

  update(endpoint: string, obj: any) {
    return this.http.put<any>(this.getUriBase() + endpoint, obj, { headers: this.getHeaders() });
  }

  delete(endpoint: string, id: string) {
    return this.http.delete<boolean>(this.getUriBase() + endpoint + `/${id}`, { headers: this.getHeaders() });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('id_token')
    });
  }
}
