import { Component, OnInit } from '@angular/core';
import { AlertState } from 'src/app/states/alert.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Alert } from 'src/app/models/alert.model';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Select(AlertState.getAlert) alert$: Observable<Alert>;

  constructor(public alertController: AlertController) { }

  ngOnInit() {
    this.alert$.subscribe(alert => {
      if(alert) {
        this.presentAlert(alert.Title, alert.Message);
      }
    });
  }

  async presentAlert(header: string, content: string) {
    // const alert = await this.alertController.create({
    //   header: header,
    //   message: content,
    //   cssClass: 'alert-wrapper',
    //   buttons: ['OK']
    // });

    // await alert.present();
  }

}
