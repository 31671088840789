import { State, Selector, Action, StateContext } from '@ngxs/store';
import { FetchContract, FetchContractList, CreateContract, UpdateContract, DeleteContract, FetchContractBy, ModifyContract, ModifyContractForEditing, FetchContractForEditing, FetchECUContractList } from '../actions/contract.actions';
import { DataService } from '../services/data.service';
import { tap } from 'rxjs/operators';
import { Contract } from '../models/contract.model';
import { SetOnLoadingState, SetOffLoadingState } from '../actions/loading.actions';
import { ShowAlert } from '../actions/alert.actions';
import { Alert, AlertType } from '../models/alert.model';
import { RateResponse } from '../models/rate.response.model';

// State of the object Contract
export class ContractStateModel {
    Contracts: Contract[];
    ContractsByTest: RateResponse[];
    actualContract: Contract;
    editContract: Contract;
}

// Explicit State
@State<ContractStateModel>({
    name: 'Contracts',
    defaults: {
        Contracts: [],
        ContractsByTest: [],
        actualContract: null,
        editContract: null,
    }
})

// Selectors and Reducers
export class ContractState {
    constructor(private dataProvider: DataService) { }

    // Selectors
    @Selector()
    static getContractList(state: ContractStateModel) {
        return state.Contracts;
    }

    @Selector()
    static getContractListByTest(state: ContractStateModel) {
        return state.ContractsByTest;
    }

    @Selector()
    static getContract(state: ContractStateModel) {
        return state.actualContract;
    }

    @Selector()
    static getContractForEditing(state: ContractStateModel) {
        return state.editContract;
    }

    // Actions
    @Action(FetchContractList)
    getAll({ getState, patchState, dispatch }: StateContext<ContractStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('contract/all')
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    Contracts: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(FetchECUContractList)
    getAllECU({ getState, patchState, dispatch }: StateContext<ContractStateModel>) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getAll('contract')
            .pipe(tap((result) => {
                console.log(result);
                
                const state = getState();
                patchState({
                    ...state,
                    Contracts: result['value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(FetchContract)
    get({ getState, patchState, dispatch }: StateContext<ContractStateModel>, { payload }: FetchContract) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('contract', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualContract: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(FetchContractForEditing)
    getForEdit({ getState, patchState, dispatch }: StateContext<ContractStateModel>, { payload }: FetchContract) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.getOne('contract', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    editContract: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(FetchContractBy)
    getBy({ getState, patchState, dispatch }: StateContext<ContractStateModel>, { payload }: FetchContractBy) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.save('contract/rate', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    ContractsByTest: result['Value']
                });
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(CreateContract)
    create({ getState, patchState, dispatch }: StateContext<ContractStateModel>, { payload }: CreateContract) {
        return this.dataProvider.save('contract', payload)
            .pipe(tap((result) => {
                const state = getState();
                patchState({
                    ...state,
                    actualContract: result['Value']
                });

                dispatch(new FetchContractList());
            }));
    }

    @Action(UpdateContract)
    update({ getState, patchState, dispatch }: StateContext<ContractStateModel>, { payload }: UpdateContract) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.update('contract', payload)
            .pipe(tap((result) => {
                if (result) {
                    const state = getState();
                    patchState({
                        Contracts: state.Contracts.map(contract => {
                            if (contract.Name === payload.Name) {
                                contract = payload;
                            }

                            return contract;
                        })
                    });
                    dispatch(new SetOffLoadingState());
                }
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }

    @Action(ModifyContract)
    modify({ getState, patchState }: StateContext<ContractStateModel>, { payload }: ModifyContract) {
        const state = getState();
        patchState({
            ...state,
            actualContract: payload
        });
    }

    @Action(ModifyContractForEditing)
    modifyForEditing({ getState, patchState }: StateContext<ContractStateModel>, { payload }: ModifyContract) {
        const state = getState();
        patchState({
            ...state,
            editContract: payload
        });
    }

    @Action(DeleteContract)
    delete({ getState, patchState, dispatch }: StateContext<ContractStateModel>, { payload }: DeleteContract) {
        dispatch(new SetOnLoadingState());
        return this.dataProvider.delete('contract', payload)
            .pipe(tap((result) => {
                if (result['Value'] >= 1) {
                    const state = getState();
                    patchState({
                        Contracts: state.Contracts.filter(contract => contract.Name !== payload)
                    });
                }
                dispatch(new FetchContractList());
                dispatch(new SetOffLoadingState());
            }, err => {
                dispatch(new ShowAlert(new Alert("Error", err.error.Message, AlertType.Error)));
                dispatch(new SetOffLoadingState());
            }));
    }
}