import { Benefit } from '../models/benefit.model';

export class FetchBenefitList {
    static readonly type = '[Benefit] Fetch List';
}

export class FetchBenefit {
    static readonly type = '[Benefit] Fetch';
    constructor(public payload: string) {}
}

export class CreateBenefit {
    static readonly type = '[Benefit] Create';
    constructor(public payload: Benefit) {}
}

export class UpdateBenefit {
    static readonly type = '[Benefit] Update';
    constructor(public payload: Benefit) {}
}

export class DeleteBenefit {
    static readonly type = '[Benefit] Delete';
    constructor(public payload: string) {}
}
